import React, { useRef, useState } from 'react';
import Map, { Marker } from 'react-map-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import CustomMarker from './CustomMarker';
import CinemaPopup from './CinemaPopup';
import { PARIS_MAP_CONFIG } from '../../utils/mapUtils';

const CinemaMap = ({ cinemas }) => {
    const [selectedCinema, setSelectedCinema] = useState(null);
    const mapRef = useRef();

    const handleMarkerClick = (cinema) => {
        setSelectedCinema(selectedCinema?.id === cinema.id ? null : cinema);
    };

    return (
        <Map
            ref={mapRef}
            initialViewState={{
                longitude: 2.3488,
                latitude: 48.8534,
                zoom: 12,
                pitch: 0,
                bearing: 0
            }}
            style={{ width: '100%', height: '100vh' }}
            mapStyle="mapbox://styles/mapbox/streets-v11"
            mapboxAccessToken="pk.eyJ1IjoiYXhlbGl0b3NsYiIsImEiOiJjbTJ1cmlqdzgwNGRyMnFzNDVtOGV4c2JmIn0.0QNACPQbwERSa2XLnkDlQg"
            terrain={null}
            maxPitch={0}
            antialias={true}
            preserveDrawingBuffer={true}
            renderWorldCopies={false}
            maxBounds={
                [
                    [2.0, 48.5],
                    [2.6, 49.0]
                ]
            }
        >
            {cinemas.map((cinema) => (
                <Marker
                    key={cinema.id}
                    longitude={cinema.geolocalisation.lon}
                    latitude={cinema.geolocalisation.lat}
                    anchor="bottom"
                >
                    <CustomMarker
                        cinema={cinema}
                        onClick={handleMarkerClick}
                    />
                </Marker>
            ))}

            <CinemaPopup
                cinema={selectedCinema}
                onClose={() => setSelectedCinema(null)}
            />
        </Map>
    );
};

export default CinemaMap;