import axios from 'axios';


const axiosInstance = axios.create({
    baseURL: "http://127.0.0.1:8000/",
    headers: {
        'Content-Type': 'application/json',
        'X-API-Key': "eaea214489b3a78590a50fe55a8e020e"
    }
});

// Intercepteur pour les requêtes
axiosInstance.interceptors.request.use(
    (config) => {
        // Vous pouvez ajouter une logique ici
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

// Intercepteur pour les réponses
axiosInstance.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        console.error('API Error:', error.response?.data || error.message);
        return Promise.reject(error);
    }
);

export default axiosInstance;