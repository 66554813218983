import React, { useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';

const CustomMarker = ({ cinema, onClick }) => {
    const markerRef = useRef(null);

    const handleClick = (e) => {
        e.stopPropagation();
        onClick(cinema);
    };

    return (
        <div
            ref={markerRef}
            onClick={handleClick}
            style={{ cursor: 'pointer' }}
        >
            <FontAwesomeIcon icon={faMapMarkerAlt} size="2x" color="red" />
        </div>
    );
};

export default CustomMarker;