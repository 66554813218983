import React from 'react';

export const Loading = ({ message = "Chargement..." }) => (
    <div className="flex justify-center items-center min-h-screen">
        <p className="text-xl">{message}</p>
    </div>
);

export const Error = ({ message }) => (
    <div className="flex justify-center items-center min-h-screen">
        <p className="text-red-500">{message}</p>
    </div>
);