import React from 'react';
import CinemaMap from './components/Map';
import { Loading, Error } from './components/Loading';
import { useCinemas } from './hooks/useCinemas';

const App = () => {
    const { cinemas, loading, error } = useCinemas();

    if (loading) return <Loading message="Chargement de la carte..." />;
    if (error) return <Error message={error} />;

    return <CinemaMap cinemas={cinemas} />;
};

export default App;