import React from 'react';
import { Popup } from 'react-map-gl';

const CinemaPopup = ({ cinema, onClose }) => {
    if (!cinema) return null;

    return (
        <Popup
            longitude={cinema.geolocalisation.lon}
            latitude={cinema.geolocalisation.lat}
            onClose={onClose}
            anchor="bottom"
            offset={[0, -5]}
        >
            <div className="p-2">
                <h3 className="font-bold">{cinema.name}</h3>
                {cinema.address && (
                    <p className="text-sm text-gray-600">{cinema.address}</p>
                )}
            </div>
        </Popup>
    );
};

export default CinemaPopup;