import { useState, useEffect } from 'react';
import axiosInstance from '../services/api';
import { addCoordinatesToCinemas } from '../utils/mapUtils';

export const useCinemas = () => {
    const [cinemas, setCinemas] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchCinemas = async () => {
            try {
                setLoading(true);
                const response = await axiosInstance.get('/cinemas');
                const cinemasWithCoordinates = addCoordinatesToCinemas(response.data.cinemas);
                setCinemas(cinemasWithCoordinates);
                setError(null);
            } catch (error) {
                console.error('Error fetching cinema data:', error);
                setError('Erreur lors du chargement des cinémas');
            } finally {
                setLoading(false);
            }
        };

        fetchCinemas();
    }, []);

    return { cinemas, loading, error };
};