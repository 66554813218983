export const addCoordinatesToCinemas = (cinemas) => {
    return cinemas.map((cinema, index) => ({
        ...cinema,
        id: index,
        geolocalisation: {
            lat: 48.8534 + (Math.random() - 0.5) * 0.1,
            lon: 2.3488 + (Math.random() - 0.5) * 0.1
        }
    }));
};

export const PARIS_MAP_CONFIG = {
    initialViewState: {
        longitude: 2.3488,
        latitude: 48.8534,
        zoom: 12,
        pitch: 0,
        bearing: 0
    },
    maxBounds: [
        [2.0, 48.5],
        [2.6, 49.0]
    ]
};